import React, { Component } from 'react';
import PropTypes from 'prop-types';

const Spinner = ({width='3rem', height='3rem'}) => (
    <div className="container">
        <div className="row align-items-center text-center">
            <div className="col">
                <div className="spinner-grow" style={{ width:width, height:height }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>

        </div>
    </div>
)

export default Spinner;