import React from 'react';
import { Link, navigate } from 'gatsby'
import FilterJobs from "../../components/Home/FilterJobs"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { trim, split, last, isEmpty, head, has, isNil, replace } from "lodash"
import { getNewsDetail } from "../../actions/news"
import Spinner from "../../components/Spinner"
import ContentDetailLoader from "../../components/Loader/ContentDetailLoader"
import { displayHumanDate } from "../../utils/helpers"

class NewsDetail extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        windowWidth:null,
        id:null,
        loaded:false,
        loading:false,
        item:null,
        error:''
      }
      this.mediaQuery = {
        desktop: 1200,
        tablet: 768,
        phone: 576,
      };
    }

    componentDidMount() {
      const { location } = this.props
      const pathArr = split(trim(location.pathname, '/'), '/')
      const pathEnd = last(pathArr)
      const detailId = split(pathEnd, '_')
      const newsId = detailId.length > 1 ? head(detailId) : null

      this.setState({
        id:newsId,
        windowWidth: document.body.clientWidth,
        loading:true
      }, ()=> {
        getNewsDetail({
          id:newsId
        }).then(res=>{
          this.setState({
            loaded:true,
            loading:false,
            item:res.data
          })
        }).catch(err=>{
          if( isNil(err.response) ) {
            this.setState({
              loading:false,
              loaded:true,
              error:'Error Internet Connection'
            })
          } else {
            if( err.response.status == 200 ) {
              this.setState({
                loading:false,
                loaded:true,
              })
            } else {
             
              this.setState({
                loaded:true,
                loading:false,
                error:has(err.response, 'data.messages') ? err.response.data.messages : 'Something went wrong from server connection'
              })
            }
          }
          
        })
      })
      
    }

    render() {
      const { location } = this.props
      const { item } = this.state
      
      if( this.state.loaded && this.state.error != '' ) {
        return <Layout>
            <SEO title="News Detail" />
            <h1>Not found</h1>
            <h5>{this.state.error}</h5>
        </Layout>
      }

      if( !item && this.state.loading ) {
        return <Layout>
            <SEO title="News Detail" />
            <ContentDetailLoader />
        </Layout>
      }

      if( item ) {
        return (<Layout>
          <SEO title={item.article_title} />
          <div className="container" style={{ marginBottom: `1.45rem` }}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                  <li className="breadcrumb-item"><Link to={'/news'}>News</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{item.article_title}</li>
                </ol>
              </nav>

              <div className="row">
                  <div className="col-12 text-center">
                  <h1 className="display-4" style={{fontSize:this.mediaQuery.phone > this.state.windowWidth ? 30 : '3.5rem'}}>{item.article_title}</h1>
                    <div className="mt-3 mb-5"> 
                        <span className="badge badge-pill badge-light" style={{fontSize:16, background:"#ddd"}}>Posted on {displayHumanDate(item.created_at)}</span>
                    </div>
                    <div className="mb-4 align-items-center">
                      {item && <img className="shadow mb-0" src={item.article_gambar} alt={item.article_gambar} style={{maxWidth:this.mediaQuery.phone > this.state.windowWidth ? '100%' : 1000 }}/>}
                    </div>
                    
                  </div>
              </div>

             <div className="row">
                 <div className="col-12">
                    <div dangerouslySetInnerHTML={{
                      __html: replace(item.article_description, '/<p[^>]*><br><\\/p[^>]*>/g', '')
                    }} />
                 </div>
             </div>
              
          </div>
        </Layout>)
      }

      return null
    }
  }

export default NewsDetail