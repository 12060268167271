import React from 'react';
import { Link } from 'gatsby'
import NewsList from "./list"
import NewsDetail from "./detail"
import { has } from "lodash"

const NewsIndex = ({location, pageContext}) => {
    if( has(pageContext, 'isDetail') && pageContext.isDetail ) {
        return (<NewsDetail location={location} />)
    } else {
        return (<NewsList location={location} />)
    }   
}

export default NewsIndex